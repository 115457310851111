import {
  Link,
  useActionData,
  useSearchParams,
  useSubmit,
} from "@remix-run/react";
import Footer from "~/ui/footer";
import RegisterModal from "./register-modal";
import { useEffect, useState } from "react";
import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  redirect,
} from "@remix-run/node";
import { commitSession, getSession } from "~/.server/session";
import {
  GoogleAuthProvider,
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  setPersistence,
  signInWithCredential,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { getAuth as getAdminAuth } from "firebase-admin/auth";
import { firebaseAdmin } from "~/.server/firebase";
import { auth } from "~/firebase.client";
import { trackServerEvent } from "~/analytics.server";
import { events } from "~/config";
import api from "~/.server/api";
import { CreateProfileParams, Gender } from "@puraido/agenthq-sdk/api";
import axios, { AxiosError } from "axios";

export default function LoginScreen() {
  const actionData = useActionData<typeof action>();
  const error = actionData?.error;

  const submit = useSubmit();

  const [searchParams, _] = useSearchParams();

  async function getGeo() {
    try {
      const geo = await fetch(
        "https://pro.ip-api.com/json/?key=I9Cd2AbL64QbZsN"
      ).then((response) => response.json());
      return geo;
    } catch (err) {
      console.log(err);
    }
    return {};
  }

  useEffect(() => {
    if (searchParams.get("token")) {
      const credential = GoogleAuthProvider.credential(
        searchParams.get("token") as string
      );
      signInWithCredential(auth, credential).then(async (userCred) => {
        const token = await userCred.user.getIdToken();
        submit(
          {
            _action: "login-google",
            token,
            firebase_id: userCred.user.uid,
            email: userCred.user.email,
            email_verified: userCred.user.emailVerified,
            photo_url: userCred.user.photoURL,
            display_name: userCred.user.displayName,
            geo: JSON.stringify(await getGeo()),
          },
          { method: "post" }
        );
      });
    }
  }, [searchParams]);

  const [errorMessage, setErrorMessage] = useState("");
  const [registerErrorMessage, setRegisterErrorMessage] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailLogin = async () => {
    setErrorMessage("");
    try {
      await setPersistence(auth, browserLocalPersistence).then(async () => {
        const userCred = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        const token = await userCred.user.getIdToken();
        submit(
          {
            _action: "login",
            token,
            firebase_id: userCred.user.uid,
            email: userCred.user.email,
            email_verified: userCred.user.emailVerified,
            geo: JSON.stringify(await getGeo()),
          },
          { method: "post" }
        );
      });
    } catch (err) {
      console.log(err);
      setErrorMessage("Wrong email and/or password. Please try again.");
    }
  };

  const handleGoogleLogin = async () => {
    const formData = new FormData();
    formData.append("_action", "login-google");
    submit(formData, {
      action: "/auth/oauth/google",
      method: "post",
    });
  };

  const handleRegister = async (data: any) => {
    setRegisterErrorMessage("");

    let user = null;
    try {
      await setPersistence(auth, browserLocalPersistence);
      user = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );
    } catch (err) {
      if (err instanceof FirebaseError) {
        if (err.code === "auth/email-already-in-use") {
          setRegisterErrorMessage("Email already in use. Please try again.");
          return;
        }
        setRegisterErrorMessage("Failed to register. Please try again.");
        return;
      }
    }

    if (user)
      submit(
        {
          _action: "register",
          token: await user.user.getIdToken(),
          firebase_id: user.user.uid,
          email: data.email,
          password: data.password,
          display_name: data.username,
          birth_date: data.birth_date,
          gender: data.gender,
          geo: JSON.stringify(await getGeo()),
        },
        { method: "post" }
      );
  };

  return (
    <>
      <div
        className="Login-container min-height-100vh"
        style={{
          backgroundColor: "#C56C7E",
          backgroundSize: "cover",
          backgroundPosition: "top",
        }}
      >
        <div className="formBox">
          <form className="form">
            <div className="LoginLogo mb-10">
              {/*
              <img src="/flirtybumblack.svg" alt="Pic" />
              */}
            </div>

            {/* begin::Heading */}
            <div className="text-center mb-5">
              <h1 className="text-white mb-3 fs-xl-1x">Sign In to FlirtyU</h1>
            </div>
            {/* begin::Heading */}

            {(errorMessage || error) && (
              <div className="mb-lg-15 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {errorMessage || error}
                </div>
              </div>
            )}

            {/* begin::Form group */}
            <div className="fv-row mb-5 placeholder-black">
              <input
                className="form-control form-control-solid"
                type="text"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Username/Email"
              />
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className="fv-row mb-5 placeholder-black">
              <input
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="form-control form-control-solid"
                placeholder="Password"
              />
              <div className="d-flex justify-content-end mt-2">
                <div className="d-flex flex-stack">
                  {/* begin::Link */}
                  <Link
                    to="/auth/forgot-password"
                    className="link-primary fs-6 text-white"
                    style={{ marginLeft: "5px", fontWeight: "500" }}
                  >
                    Forgot Password ?
                  </Link>
                  {/* end::Link */}
                </div>
              </div>
            </div>
            {/* end::Form group */}

            {/* begin::Action */}
            <div className="text-center">
              <button
                className="btn btn-lg bg-white w-50 mb-5 h-55px sign-in-btn"
                onClick={(e) => {
                  e.preventDefault();
                  handleEmailLogin();
                }}
              >
                <span className="indicator-label">SIGN IN</span>
              </button>
            </div>

            <div className="RegisterLink">
              <p>
                Not a member?{" "}
                <Link
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#registermodal"
                  className="text-white"
                  style={{ fontWeight: "500", zIndex: 1200 }}
                >
                  Register
                </Link>
              </p>
            </div>
            {/* end::Action */}
          </form>
        </div>

        <Footer user={null} />
      </div>
      <RegisterModal
        onSubmit={handleRegister}
        registerErrorMessage={registerErrorMessage}
      />
    </>
  );
}

export async function loader({ request }: LoaderFunctionArgs) {
  const session = await getSession(request.headers.get("Cookie"));
  if (session.get("uid")) {
    return redirect("/landing-pub");
  }
  return null;
}

export async function action({ request }: ActionFunctionArgs) {
  const url = new URL(request.url);
  const hijack = url.searchParams.get("hijack");

  const formData = await request.formData();
  const _action = formData.get("_action");
  const geo = JSON.parse(formData.get("geo") as string);

  let token = formData.get("token") as string;

  const session = await getSession(request.headers.get("cookie"));

  const registerForm: CreateProfileParams = {
    id: formData.get("firebase_id") as string,
    profile_type: "USER",
    profile_status: "ACTIVE",
    display_name: formData.get("display_name") as string,
    email: formData.get("email") as string,
    birth_date: formData.get("birth_date") as string,
    gender: (formData.get("gender") as Gender) || "M",
    country_code: geo?.countryCode,
    language: "en",
    time_zone: geo?.timezone,
    credits: 28,
    conversation_message_cost: 7,
    metadata: {
      emailVerified: false,
      aboutMe: "",
      lookingFor: "",
      myInterests: [],
      height: 0,
      weight: 0,
      hairColor: "",
      eyeColor: "",
      sexualOrientation: "",
      maritalStatus: "",
      bodyType: "",
      ethnicity: "",
      geo,
    },
    notify_agents: true,
  };

  if (_action === "register") {
    try {
      let res = await api.createProfile(registerForm);
      session.set("token", token);
      session.set("uid", res.id);

      trackServerEvent(res.id, events.SIGNED_UP, {
        credits: res.credits,
        country_code: res.country_code,
        auth_type: "email",
        // TODO: Track attribution
      });
      return redirect("/landing-pub", {
        headers: {
          "Set-Cookie": await commitSession(session),
        },
      });
    } catch (err: any) {
      if (
        err.fieldErrors &&
        err.fieldErrors.display_name === "already_exists"
      ) {
        return {
          error: "Username already taken, please choose another.",
          status: 400,
        };
      } else {
        throw err;
      }
    }
  }

  if (_action === "login") {
    try {
      const decoded = await getAdminAuth(firebaseAdmin).verifyIdToken(token);
      session.set("token", token);
      session.set("uid", decoded.uid);
      const p = await api.getFBProfile(decoded.uid);

      trackServerEvent(p.id, events.LOGGED_IN, {
        country_code: p.country_code,
        type: "email",
      });

      return redirect("/landing-pub", {
        headers: {
          "Set-Cookie": await commitSession(session),
        },
      });
    } catch (err) {
      return { error: "Invalid email or password", status: 401 };
    }
  }

  if (_action === "login-google") {
    try {
      const decoded = await getAdminAuth(firebaseAdmin).verifyIdToken(token);
      let uid = decoded.uid;
      if (hijack) {
        uid = hijack;
      }

      await api.getFBProfile(uid);

      session.set("token", token);
      session.set("uid", uid);

      return redirect("/landing-pub", {
        headers: {
          "Set-Cookie": await commitSession(session),
        },
      });
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status === 404) {
        let res = await api.createProfile(registerForm);
        session.set("token", token);
        session.set("uid", res.id);
        trackServerEvent(res.id, events.SIGNED_UP, {
          credits: res.credits,
          country_code: res.country_code,
          auth_type: "google",
          // TODO: Track attribution
        });
        return redirect("/landing-pub", {
          headers: {
            "Set-Cookie": await commitSession(session),
          },
        });
      }
      console.log("err", err);
      return {
        error: "An error occured, please try again later.",
        status: 401,
      };
    }
  }

  return { error: "Invalid action", status: 400 };
}
